import { createApp, markRaw } from 'vue'
import App from './App.vue'
import router from './router'
import * as Sentry from '@sentry/vue'

import { IonicVue } from '@ionic/vue'

import axios from 'axios'
import { PiniaPluginContext, createPinia } from 'pinia'

import { Drivers, Storage } from '@ionic/storage'
import CordovaSQLiteDriver from 'localforage-cordovasqlitedriver'

import * as amplitude from '@amplitude/analytics-browser'
import { sessionReplayPlugin } from '@amplitude/plugin-session-replay-browser'

// @ts-ignore
import VueVirtualScroller from 'vue-virtual-scroller'

import Vue3TouchEvents from 'vue3-touch-events'

import '@/assets/styles/main.css'

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css'

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css'
import '@ionic/vue/css/structure.css'
import '@ionic/vue/css/typography.css'

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css'
import '@ionic/vue/css/float-elements.css'
import '@ionic/vue/css/text-alignment.css'
import '@ionic/vue/css/text-transformation.css'
import '@ionic/vue/css/flex-utils.css'
import '@ionic/vue/css/display.css'

/* Theme variables */
import './theme/variables.css'

import 'vue-virtual-scroller/dist/vue-virtual-scroller.css'

import { Capacitor } from '@capacitor/core'
import { Keyboard, KeyboardResize } from '@capacitor/keyboard'

const api = import.meta.env.VITE_API_URL
axios.defaults.baseURL = api

function localStoragePiniaPlugin() {
  const ionicStorage = new Storage({
    driverOrder: [
      CordovaSQLiteDriver._driver,
      Drivers.IndexedDB,
      Drivers.LocalStorage,
    ],
  })
  const setupStorage = async () => {
    await ionicStorage.create()
    await ionicStorage.defineDriver(CordovaSQLiteDriver)
  }
  setupStorage()
  return { ionicStorage: markRaw(ionicStorage), stateLoaded: false }
}

function localAuthPiniaPlugin({ store }: PiniaPluginContext) {
  store.ionicStorage.get('token').then((token: string) => {
    store.token = token ? token : null
  })

  // TODO: Remove this in favor of the explicit loading in the login page?
  store.ionicStorage.get('refreshToken').then((refreshToken: string) => {
    store.refreshToken = refreshToken ? refreshToken : null
    // console.log('localAuthPiniaPlugin - Loaded refresh', store.refreshToken)
  })

  store.ionicStorage
    .get('user')
    .then((user: string) => {
      store.user = user ? user : null
    })
    .finally(() => {
      store.stateLoaded = true
    })
}

function routerPiniaPlugin({ store }: PiniaPluginContext) {
  store.router = markRaw(router)
}

const pinia = createPinia()

const app = createApp(App)

Sentry.init({
  app,
  dsn: 'https://04c170ec7c4e132e8173cdcf898ff5db@o4506241915027456.ingest.sentry.io/4506241927413760',
  integrations: [
    Sentry.browserTracingIntegration({ router }),
    Sentry.replayIntegration(),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
  enabled: import.meta.env.PROD,
  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', /^https:\/\/api.balancebudget\.app/],

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
})
const sessionReplayTracking = sessionReplayPlugin()
amplitude.add(sessionReplayTracking)
amplitude.init(import.meta.env.VITE_AMPLITUDE_API_KEY, {
  defaultTracking: true,
})

if (Capacitor.isNativePlatform()) {
  Keyboard.setResizeMode({ mode: KeyboardResize.Body })
}

app
  .use(IonicVue, { swipeBackEnabled: true })
  .use(pinia)
  .use(router)
  .use(VueVirtualScroller)
  .use(Vue3TouchEvents)

pinia.use(localStoragePiniaPlugin)
pinia.use(localAuthPiniaPlugin)
pinia.use(routerPiniaPlugin)
pinia.use(() => {
  return { axios: markRaw(axios) }
})
pinia.use(() => {
  return { amplitude: amplitude }
})

app.provide('api', api)
app.provide('axios', axios)
app.provide('amplitude', amplitude)

router.isReady().then(() => {
  app.mount('#app')
})
