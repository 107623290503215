<template>
  <ion-app>
    <ion-router-outlet />
    <messages-controller />
  </ion-app>
</template>

<script setup lang="ts">
import { IonApp, IonRouterOutlet } from '@ionic/vue'
import { onMounted } from 'vue'
import { useAuthStore } from './stores/auth'
import MessagesController from './components/MessagesController.vue'

const auth = useAuthStore()

onMounted(() => {
  auth.setInterceptor()
  if (auth.user) {
    auth.setAuthHeader()
  }
})
</script>
