import { Message } from '@/types/Message'
import { defineStore } from 'pinia'

export const useMessagesStore = defineStore('messages', {
  state: () => ({
    messages: [] as Message[],
  }),
  getters: {},
  actions: {},
})
