// progressStore.js
import { defineStore } from 'pinia'

export enum LoadingType {
  FetchAllTransactions = 'fetch-all-transactions',
  FetchTransaction = 'fetch-transaction',
  UpdateTransaction = 'update-transaction',
  FetchAllCategories = 'fetch-all-categories',
  FetchCategory = 'fetch-category',
  PostCategory = 'post-category',
  Signup = 'signup',
  Login = 'login',
  Logout = 'logout',
}

export const useLoadingStore = defineStore('loading', {
  state: () => ({
    loadingTypes: [] as LoadingType[],
  }),
  getters: {
    isLoading: (state) => (loadingType?: LoadingType) => {
      return loadingType
        ? state.loadingTypes.includes(loadingType)
        : state.loadingTypes.length > 0
    },
  },
  actions: {
    startLoading(loadingType: LoadingType) {
      if (!this.loadingTypes.includes(loadingType)) {
        this.loadingTypes.push(loadingType)
      }
    },
    finishLoading(loadingType: LoadingType) {
      this.loadingTypes = this.loadingTypes.filter((t) => t !== loadingType)
    },
  },
})
